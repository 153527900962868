<template>
  <div class="right">
    <span class="spen">收货地址</span>
    <div class="daohang">
      <ul class="ul">
        <router-link :to="{ name: 'User' }" tag="li">个人基本信息</router-link>
        <router-link :to="{ name: 'Safe' }" tag="li">账户安全</router-link>
        <router-link :to="{ name: 'Bind' }" tag="li">账号绑定</router-link>
        <router-link :to="{ name: 'Address' }" tag="li">收货地址</router-link>
      </ul>
    </div>
    <div class="add">
      <div class="add_address" @click="address">
        新增收货地址
      </div>
      <div class="created">
        您已创建
        <span class="color">{{ list.length }}</span>
        个收货地址
        <!-- ，最多可创建
        <span class="color">20</span>个 -->
      </div>
    </div>
    <Loading v-if="isLoading" />
    <div v-else>
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="item.isDefault == 0 ? 'list1 list' : 'list'"
      >
        <div class="name">
          <div class="Left">
            <div class="ming">{{ item.receiverName }}</div>
            <div class="sheng">{{ item.receiverProvince }}</div>
          </div>
          <div class="Right" @click="del(item.receiverId)">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="shouhuo">
          <div class="shou_L">收货人：</div>
          <div class="shou_R">{{ item.receiverName }}</div>
        </div>
        <div class="shouhuo">
          <div class="shou_L">所在地区：</div>
          <div class="shou_R">
            {{ item.receiverProvince }}{{ item.receiverCity
            }}{{ item.receiverDistrict }}
          </div>
        </div>
        <div class="shouhuo">
          <div class="shou_L">地址：</div>
          <div class="shou_R">{{ item.receiverAddress }}</div>
        </div>
        <div class="shouhuo">
          <div class="shou_L">联系方式：</div>
          <div class="shou_R">{{ item.receiverPhone }}</div>
        </div>
        <div class="moren">
          <div
            class="moren_L"
            v-if="item.isDefault == 0"
            @click="isDefault(item)"
          >
            设为默认
          </div>
          <div class="moren_R" @click="update(item)">编辑</div>
        </div>
      </div>
    </div>

    <el-dialog title="新增收货地址" :visible.sync="dialogFormVisible" :before-close="handleClose">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="收货人" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="手机号码"
          :label-width="formLabelWidth"
          prop="number"
          ><el-input v-model="form.number" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所在省" :label-width="formLabelWidth" prop="valueProvince">
          <!--  <el-input v-model="form.region" autocomplete="off"></el-input>-->
          <el-select style="width: 100%;" v-model="form.valueProvince" filterable placeholder="请选择省" @change="changeProvince">
            <el-option
                    v-for="item in provinceList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在市" :label-width="formLabelWidth" prop="valueCity">
          <!--  <el-input v-model="form.shi" autocomplete="off"></el-input>-->
          <el-select style="width: 100%;" v-model="form.valueCity" filterable placeholder="请选择市" @change="changeCity">
            <el-option
                    v-for="item in cityOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在区" :label-width="formLabelWidth" prop="valueOrigin">
          <!--  <el-input v-model="form.qu" autocomplete="off"></el-input>-->
          <el-select style="width: 100%;" v-model="form.valueOrigin" filterable placeholder="请选择区" @change="changeOrigin">
            <el-option
                    v-for="item in originOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="详细地址"
          :label-width="formLabelWidth"
          prop="xiangxi"
        >
          <el-input
            type="textarea"
            v-model="form.xiangxi"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="固定电话"
          :label-width="formLabelWidth"
          prop="dianhua"
        >
          <el-input v-model="form.dianhua" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="邮政编码"
          :label-width="formLabelWidth"
          prop="email"
        >
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{ $t('button.Cancel') }}</el-button>
        <el-button type="primary" @click="submitForm('form')">{{ $t('button.Confirm') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑收货地址" :visible.sync="dialogFormVisible1" :before-close="handleClose">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="收货人" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所在省" :label-width="formLabelWidth" prop="valueProvince">
<!--          <el-input v-model="form.region" autocomplete="off"></el-input>-->
          <el-select style="width: 100%;" v-model="form.valueProvince" filterable placeholder="请选择省" @change="changeProvince">
            <el-option
                    v-for="item in provinceList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在市" :label-width="formLabelWidth" prop="valueCity">
<!--          <el-input v-model="form.shi" autocomplete="off"></el-input>-->
          <el-select style="width: 100%;" v-model="form.valueCity" filterable placeholder="请选择市" @change="changeCity">
            <el-option
                    v-for="item in cityOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在区" :label-width="formLabelWidth" prop="valueOrigin">
<!--          <el-input v-model="form.qu" autocomplete="off"></el-input>-->
          <el-select style="width: 100%;" v-model="form.valueOrigin" filterable placeholder="请选择区" @change="changeOrigin">
            <el-option
                    v-for="item in originOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="详细地址"
          :label-width="formLabelWidth"
          prop="xiangxi"
        >
          <el-input
            type="textarea"
            v-model="form.xiangxi"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号码"
          :label-width="formLabelWidth"
          prop="number"
        >
          <el-input v-model="form.number" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="固定电话"
          :label-width="formLabelWidth"
          prop="dianhua"
        >
          <el-input v-model="form.dianhua" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="邮政编码"
          :label-width="formLabelWidth"
          prop="email"
        >
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">{{ $t('button.Cancel') }}</el-button>
        <el-button type="primary" @click="submitForm1('form')">{{ $t('button.Confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
import {chinaAddress} from '../../../utils/china_address.js';
export default {
  data() {
    return {
      dialogFormVisible: false,
      dialogFormVisible1: false,
      form: {
        name: '',
        regionId:'',
        region: '',
        xiangxi: '',
        number: '',
        dianhua: '',
        email: '',
        bieming: '',
        shiId:'',
        shi: '',
        quId:'',
        qu: '',
        valueProvince:'',
        valueCity:'',
        valueOrigin:''
      },
      receiverId: '',
      userId: '',
      rules: {
        name: [
          { required: true, message: '请输入收货人姓名', trigger: 'blur' },
          { min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur' },
          { pattern: /^[\u4e00-\u9fa5]+$/, message: '请输入正确的姓名', trigger: 'blur' }
        ],
          valueProvince: [{ required: true, message: '请选择省', trigger: 'change' }],
          valueCity: [{ required: true, message: '请选择市', trigger: 'change' }],
          valueOrigin: [{ required: true, message: '请选择区', trigger: 'change' }],
        xiangxi: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
          {
            min: 3,
            max: 50,
            message: '长度在 3 到 50 个字符',
            trigger: 'blur',
          },
        ],
        number: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入有效的手机号', trigger: 'blur' }
        ],
      },
      formLabelWidth: '120px',
      list: [],
      isLoading: true,

      provinceList: [], // 省列表
      cityList: [], // 市列表
      originList: [], // 区列表
      valueProvince: '', // 所选的省Id
      nameProvince: '', // 所选的省
      valueCity: '', // 所选的市Id
      nameCity: '', // 所选的市
      valueOrigin: '', // 所选的区Id
      nameOrigin: '', // 所选的区
      cityOptions: [], // 市下拉框数据
      originOptions: [],// 区下拉框数据
    };
  },
  //"dialogFormVisible = false"
  created() {
    this.getorder();
    this._getJsonData();
  },
  methods: {
     handleClose(done) {
        this.$confirm(this.$t('message.Confirm_closure'))
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
    getorder() {
      get('api/address/selReceiverAddressList').then((res) => {
        this.isLoading = false;
        this.list = res.data.data;
      });
    },
    //新增
    submitForm(formName) {
      this.form.regionId=this.valueProvince;
      this.form.region=this.nameProvince;
      this.form.shiId=this.valueCity;
      this.form.shi=this.nameCity;
      this.form.quId=this.valueOrigin;
      this.form.qu=this.nameOrigin;
      this.$refs[formName].validate((valid) => {
            if (valid) {
              this.dialogFormVisible = false;
              post('api/address/insertAddress', {
                receiverName: this.form.name,
                receiverPhone: this.form.number,
                receiverProvinceId: this.form.regionId,
                receiverProvince: this.form.region,
                receiverCityId: this.form.shiId,
                receiverCity: this.form.shi,
                receiverDistrictId: this.form.quId,
                receiverDistrict: this.form.qu,
                receiverAddress: this.form.xiangxi,
                receiverFixedTelephone: this.form.dianhua,
                postcode: this.form.email,
              }).then((res) => {
                this.$message.success({
                  message: '新增成功',
                });
                  // 清空表单
                  this.form={
                      name: '',
                      regionId:'',
                      region: '',
                      xiangxi: '',
                      number: '',
                      dianhua: '',
                      email: '',
                      bieming: '',
                      shiId:'',
                      shi: '',
                      quId:'',
                      qu: '',
                      valueProvince:'',
                      valueCity:'',
                      valueOrigin:''
                  }
                //this.getorder();
                this.$router.go(0)
              });
            } else {
              return false;
            }
          });
    },
    //编辑
    submitForm1(formName) {
      this.form.regionId=this.valueProvince;
      this.form.region=this.nameProvince;
      this.form.shiId=this.valueCity;
      this.form.shi=this.nameCity;
      this.form.quId=this.valueOrigin;
      this.form.qu=this.nameOrigin;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          post('api/address/updateAddress', {
            receiverId: this.receiverId,
            userId: this.userId,
            receiverName: this.form.name,
            receiverPhone: this.form.number,
            receiverProvinceId: this.form.regionId,
            receiverProvince: this.form.region,
            receiverCityId: this.form.shiId,
            receiverCity: this.form.shi,
            receiverDistrictId: this.form.quId,
            receiverDistrict: this.form.qu,
            receiverAddress: this.form.xiangxi,
            receiverFixedTelephone: this.form.dianhua,
            postcode: this.form.email,
          }).then((res) => {
            this.$message.success({
              message: '编辑成功',
            });
            this.dialogFormVisible1 = false;
            // 清空表单
              this.form={
                  name: '',
                  regionId:'',
                  region: '',
                  xiangxi: '',
                  number: '',
                  dianhua: '',
                  email: '',
                  bieming: '',
                  shiId:'',
                  shi: '',
                  quId:'',
                  qu: '',
                  valueProvince:'',
                  valueCity:'',
                  valueOrigin:''
              }
            //this.getorder();
            this.$router.go(0)
          });
        } else {
          return false;
        }
      });
    },
    //点击编辑
    update(e) {
      this.form.name = e.receiverName;
      this.form.number = e.receiverPhone;
      this.form.regionId = e.receiverProvinceId;
      this.form.region = e.receiverProvince;
      this.form.shiId = e.receiverCityId;
      this.form.shi = e.receiverCity;
      this.form.quId = e.receiverDistrictId;
      this.form.qu = e.receiverDistrict;
      this.form.xiangxi = e.receiverAddress;
      this.form.dianhua = e.receiverFixedTelephone;
      this.form.email = e.postcode;
      this.userId = e.userId;
      this.receiverId = e.receiverId;
      this.dialogFormVisible1 = true;

        this.form.valueProvince=e.receiverProvinceId;
        this.form.valueCity=e.receiverCityId;
        this.form.valueOrigin = e.receiverDistrictId;

      this.valueProvince=e.receiverProvinceId;
      this.nameProvince=e.receiverProvince;
      this.valueCity=e.receiverCityId;
      this.nameCity = e.receiverCity;
      this.valueOrigin = e.receiverDistrictId;
      this.nameOrigin = e.receiverDistrict;

      this.changeProvince(e.receiverProvinceId);
      this.changeCity(e.receiverCityId);
      this.changeOrigin(e.receiverDistrictId);
    },
    //点击新增
    address() {
      this.form.name = '';
      this.form.number = '';
      this.form.regionId = '';
      this.form.region = '';
      this.form.shiId = '';
      this.form.shi = '';
      this.form.quId = '';
      this.form.qu = '';
      this.form.xiangxi = '';
      this.form.dianhua = '';
      this.form.email = '';
      this.form.valueProvince='';
      this.form.valueCity = '';
      this.form.valueOrigin ='';
      this.dialogFormVisible = true;

      this.valueProvinceId='';
      this.valueProvince='';
      this.valueCityId='';
      this.valueCity = '';
      this.valueOriginId = '';
      this.valueOrigin ='';
    },
    //删除收货地址
    del(e) {
      this.$confirm('是否删除此条收货地址?', this.$t('common.Confirmation'), {
        confirmButtonText: this.$t('button.Confirm'),
        cancelButtonText: this.$t('button.Cancel'),
        type: 'warning',
      }).then(() => {
        this.isLoading = true;
        get('api/address/deleteRececvierById?receiverId=' + e + '').then(
          (res) => {
            this.$message.success({
              message: this.$t('message.Successfully_deleted'),
            });
            this.getorder();
          }
        );
      });
    },
    //设为默认
    isDefault(e) {
      this.$confirm('是否设置为默认收货地址?', this.$t('common.Confirmation'), {
        confirmButtonText: this.$t('button.Confirm'),
        cancelButtonText: this.$t('button.Cancel'),
        type: 'warning',
      }).then(() => {
        this.isLoading = true;
        get('api/address/setdefault?receiverId=' + e.receiverId + '').then(
          (res) => {
            this.$message.success({
              message: '设置成功',
            });
            //this.isLoading = false;
            this.getorder();
          }
        );
      });
    },

    // 选择省
    changeProvince(val) {
      this.provinceList.forEach((province, index) => {
        if (val.toString() === this.provinceList[index].value) {
            this.valueProvince=this.provinceList[index].value;
            this.nameProvince=this.provinceList[index].label;
            this.cityOptions = this.provinceList[index].children;
            this.originOptions = this.provinceList[index].children[0].children;
            this.valueCity = '';
            this.valueOrigin = '';
          // 如果选中省之后想市区默认选中
          // this.valueCity = this.provinceList[index].children[0].value;
          // this.nameCity = this.provinceList[index].children[0].label;
          // this.valueOrigin = this.provinceList[index].children[0].children[0].value;
          // this.nameOrigin=this.provinceList[index].children[0].children[0].label;
        }
      })
    },
    // 选择市
    changeCity(val) {
      this.cityList.forEach((city, index) => {
        if (val.toString() === this.cityList[index].value) {
            this.valueCity=this.cityList[index].value;
            this.nameCity=this.cityList[index].label;
            this.originOptions = this.cityList[index].children;
            this.valueOrigin = '';
          //如果选中市之后默认区选中
          // this.valueOrigin = this.cityList[index].children[0].value;
          // this.nameOrigin=this.cityList[index].children[0].label;
        }
      })
    },
    // 选择区
    changeOrigin(val) {
      this.originList.forEach((origin, index) => {
        if (val.toString() === this.originList[index].value) {
          this.valueOrigin = this.originList[index].value;
          this.nameOrigin=this.originList[index].label;
        }
      })
    },
    // 初始化省市区数据
    _getJsonData() {
      this.provinceList = []
      this.cityList = []
      this.originList = []
      chinaAddress().forEach((item) => {
        if (item.value.match(/0000$/)) {
          this.provinceList.push({
            value: item.value,
            label: item.name,
            children: []
          })
        } else if (item.value.match(/00$/)) {
          this.cityList.push({
            value: item.value,
            label: item.name,
            children: []
          })
        } else {
          this.originList.push({
            value: item.value,
            label: item.name
          })
        }
      })
      for (let index in this.provinceList) {
        for (let index1 in this.cityList) {
          if (this.provinceList[index].value.slice(0, 2) === this.cityList[index1].value.slice(0, 2)) {
            this.provinceList[index].children.push(this.cityList[index1])
          }
        }
      }
      for(let item1 in this.cityList) {
        for(let item2 in this.originList) {
          if (this.originList[item2].value.slice(0, 4) === this.cityList[item1].value.slice(0, 4)) {
            this.cityList[item1].children.push(this.originList[item2])
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }

  .daohang {
    font-size: 15px;
    color: #272727;
    margin-right: 25px;
    height: 60px;

    .ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      width: 400px;
      li {
        list-style: none;
        cursor: pointer;
        &.router-link-exact-active.router-link-active {
          color: #3661fe;
        }
      }
    }
  }
  .add {
    height: 35px;
    display: flex;
    align-items: center;
    .add_address {
      width: 137px;
      height: 100%;
      border: 1px solid #1750ff;
      color: #1750ff;
      border-radius: 5px;
      line-height: 35px;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
    }
    .created {
      font-size: 12px;
      color: #797979;
      margin-left: 20px;
      .color {
        color: #ff902a;
      }
    }
  }

  .list {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 895px;
    height: 240px;
    background: #f0f5f9;
    padding: 17px 30px 0 55px;
    .name {
      font-size: 18px;
      color: #333331;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      .Left {
        display: flex;
        .ming {
          width: 90px;
          text-align: right;
        }
        .sheng {
          margin-left: 10px;
        }
      }
      .Right {
        cursor: pointer;
      }
    }
    .shouhuo {
      margin-top: 20px;
      display: flex;
      .shou_L {
        width: 100px;
        font-size: 14px;
        text-align: right;
        color: #78787a;
      }
      .shou_R {
        color: #3f3f3f;
      }
    }
    .moren {
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      color: #1951fe;
      .moren_L {
        cursor: pointer;
      }
      .moren_R {
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
  .list1 {
    background: rgb(230, 219, 212);
  }
}
</style>
